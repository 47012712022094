import React, { useState } from "react";
import styled from "styled-components";
import Arrow from "../../images/arrow_down.svg";
import { breakPoints } from "../theme";

// background: linear-gradient(116.14deg, #101926 -22.83%, rgba(74, 91, 114, 0) 123.39%);

const Card = styled.div`
  margin-bottom: 2rem;
  background: ${(props) =>
    props.pro
      ? props.theme.faqCardBackgroundPRO
      : props.theme.faqCardBackground};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: white;
  .faqcard_title {
    padding: 2.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    img {
      margin-left: 1rem;
    }
  }

  :hover {
    border-radius: 6px;
  }

  .faqcard_answer {
    padding: 0 2.5rem 2.5rem;
    font-family: Telex;
    font-size: 20px;
    p {
      margin: 0 0 1rem;
      font-size: 20px;
      :last-of-type {
        margin: 0;
      }
    }
    div {
      margin-bottom: 1rem;
      :last-of-type {
        margin: 0;
      }
    }
    h4 {
      margin: 0 0 0.5rem;
    }
    a {
      color: ${(props) => props.theme.faqLink};
      text-decoration: underline;
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    .faqcard_title {
      padding: 1.75rem;
      font-size: 20px;
    }
    .faqcard_answer {
      padding: 0 1.75rem 1.75rem;
      font-family: Telex;
      font-size: 18px;
      p {
        font-family: Telex;
        font-size: 16px;
      }
    }
  }
`;

const FAQCard = ({ children, title, isPro = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <Card pro={isPro} className="faqcard_wrap">
      <div className="faqcard_title" onClick={() => setOpen(!open)}>
        {title}
        <img
          style={{ transform: open && `rotate(180deg)` }}
          src={Arrow}
          alt="Open Answer"
        ></img>
      </div>
      <div
        style={{ display: open ? "block" : "none" }}
        className="faqcard_answer"
      >
        {children}
      </div>
    </Card>
  );
};

export default FAQCard;
