import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";

// Components
import { lightTheme } from "../components/theme";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Intro from "../components/pro/Intro";
import IntegrationPro from "../components/pro/IntegrationPro";
import OurMissionPro from "../components/pro/OurMissionPro";
import DeFiTools from "../components/pro/DeFiTools";
import StrategyManager from "../components/pro/StrategyManager";

// Assets
import ResearchManager from "../components/pro/ResearchManager";
import Discover from "../components/pro/Discover";
import ProInfo from "../components/pro/ProInfo";
import ComingSoon from "../components/pro/ComingSoon";
import MetaComponent from "../components/MetaComponent";
import FAQ from "../components/faq";
import AdvancedTools from "../components/pro/AdvancedTools";
import Enhance from "../components/pro/Enhance";

const Wrapper = styled.div`
  min-height: 100vh;
  background: ${(props) => props.theme.proBlack};
  position: relative;
  color: white;
  p {
    font-family: Telex;
    margin: 0;
    font-size: 24px;
  }
`;

const Pro = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Pro" />
      <Wrapper>
        <Menu isPro />
        <Intro />
        <IntegrationPro />        
        <Enhance />
        <OurMissionPro />
        <ResearchManager />
        <Discover />
        <StrategyManager />
        <ProInfo />
        <AdvancedTools />
        <ComingSoon />
        <FAQ id="faqpro" isPro />
        <Footer isPro />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Pro;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
