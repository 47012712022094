import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

// Assets
import Uniswap from "../../images/pro/integrations/uni.svg";
import Curve from "../../images/pro/integrations/curve.svg";
import Aave from "../../images/pro/integrations/aave.svg";
import Inch from "../../images/pro/integrations/1inch.svg";
import Compound from "../../images/pro/integrations/Comp.svg";
import DyDx from "../../images/pro/integrations/dydx.svg";
import PancakeSwap from "../../images/pro/integrations/pancakeswap.svg";
import ApeSwap from "../../images/pro/integrations/apeswap.svg";
import Alkemi from "../../images/pro/integrations/Alkemi.svg";
import ETH from "../../images/pro/integrations/eth.svg";
import BSC from "../../images/pro/integrations/bsc.svg";
import Polygon from "../../images/pro/integrations/polygon.svg";
import Avax from "../../images/pro/integrations/avax.svg";
import Fantom from "../../images/pro/integrations/ftm.svg";
import Arbitrum from "../../images/pro/integrations/arbitrum.svg";
import Optimism from "../../images/pro/integrations/optimism.svg";
import Cronos from "../../images/pro/integrations/cronos.svg";

const Wrapper = styled.div`
  background: none;
  margin: 5rem 0 8rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .integration_row {
    display: flex;
    max-width: 1100px;
    gap: 1.5rem 3.5rem;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
      padding: 0 1.25rem;
      img {
        height: 32px;
        width: 32px;
      }
    }
  }
  .only_mobile {
    display: none;
    @media (max-width: ${breakPoints.md}px) {
      display: flex;
    }
  }
`;

const IntegrationPro = () => {
  return (
    <Wrapper>
      <div className="integration_row">
        <img src={Aave} alt="Aave" />
        <img src={Uniswap} alt="Uniswap" />
        <img src={Curve} alt="Curve" />
        <img src={Inch} alt="Inch" />
        <img src={DyDx} alt="DyDx" />
        <img src={ApeSwap} alt="ApeSwap" />
        <img src={PancakeSwap} alt="PancakeSwap" />
        <img src={Alkemi} alt="Alkemi" />
        <img src={Compound} alt="Compound" />
      </div>
      <div className="integration_row">
        <img src={ETH} alt="ETH" />
        <img src={BSC} alt="BSC" />
        <img src={Polygon} alt="Polygon" />
        <img src={Avax} alt="Avalanche" />
        <img src={Fantom} alt="Fantom" />
        <img src={Arbitrum} alt="Arbitrum" />
        <img src={Optimism} alt="Optimism" />
        <img src={Cronos} alt="Cronos" />
      </div>
      <div className="integration_row only_mobile">
        <img src={Aave} alt="Aave" />
        <img src={Uniswap} alt="Uniswap" />
        <img src={Curve} alt="Curve" />
        <img src={Inch} alt="Inch" />
        <img src={DyDx} alt="DyDx" />
        <img src={ApeSwap} alt="ApeSwap" />
        <img src={PancakeSwap} alt="PancakeSwap" />
        <img src={Alkemi} alt="Alkemi" />
        <img src={Compound} alt="Compound" />
        <img src={ETH} alt="ETH" />
        <img src={BSC} alt="BSC" />
        <img src={Polygon} alt="Polygon" />
        <img src={Avax} alt="Avalanche" />
        <img src={Fantom} alt="Fantom" />
        <img src={Arbitrum} alt="Arbitrum" />
        <img src={Optimism} alt="Optimism" />
        <img src={Cronos} alt="Cronos" />
      </div>
    </Wrapper>
  );
};

export default IntegrationPro;
