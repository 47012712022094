import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 0 8rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  * {
    z-index: 3;
  }
  h2 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  a:first-of-type {
    margin-bottom: 2rem;
  }
  .pro_readmore {
    color: white;
    font-weight: 600;
    text-decoration: underline;
    font-size: 20px;
    :hover {
      color: ${(props) => props.theme.proText};
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    h2 {
      font-size: 38px;
    }
  }
`;

const ComingSoon = ({ setOpen }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <a href="https://pro.keyfi.com" target="_blank" rel="noreferrer">
        <Button primary>{t("homepage.menu.launchApp")}</Button>
      </a>
      <a
        href="https://medium.com/keyfi-news/keyfi-pro-is-live-8f00d8076964"
        target="_blank"
        rel="noreferrer"
        className="pro_readmore"
      >
        {t("addition.readMore")}
      </a>
    </Wrapper>
  );
};

export default ComingSoon;
