import React from "react";
import styled from "styled-components";

// Assets
import Discovery from "../../images/pro/Discovery_White.svg";
import ResearchUI from "../../images/pro/ResearchUI.png";
import ResearchUIMobile from "../../images/pro/ResearchUI_Mobile.png";
import AlertsUIMobile from "../../images/pro/Alerts_Mobile.png";

import { breakPoints } from "../theme";

import { useTranslation } from "react-i18next";

const OutsideWrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 6rem auto 0;
  padding: 0 1.25rem;
  position: relative;
  overflow: hidden;
  @media (max-width: ${breakPoints.md}px) {
    margin-top: 0;
  }

  .research_image {
    position: relative;
    display: block;
    padding: 0 5rem 2rem;
    z-index: 3;
    width: 100%;
    margin-top: 3.5rem;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
    .research_image_main {
      display: block;
      width: 100%;
    }
    .research_image_main_mobile {
      height: 75%;
      position: absolute;
      bottom: -5%;
      overflow: hidden;
      :first-of-type {
        left: 0%;
      }
      :last-of-type {
        right: 0%;
      }
    }
  }
  .research_image_mobile {
    display: none;
    @media (max-width: ${breakPoints.md}px) {
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
      max-width: 325px;
      margin: 1rem auto 0;
      display: block;
    }
  }
`;

const ResearchIntro = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  * {
    z-index: 2;
  }

  h2 {
    font-size: 48px;
    font-weight: 700;
    margin: 1rem 0;
  }
  p {
    font-size: 24px;
    color: ${(props) => props.theme.proText};
    line-height: 32px;
  }
  @media (max-width: ${breakPoints.md}px) {
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 20px !important;
      line-height: 30px;
    }
    img {
      max-height: 50px;
    }
  }
`;

const Ellipse = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  top: ${(props) => (props.top ? props.top : `8%`)};
  left: ${(props) => (props.left ? props.left : `35%`)};
  background: radial-gradient(56.93% 56.93% at 40% 43.07%, #131ac2 0%, rgba(17, 20, 23, 0) 73.36%);
  opacity: 0.4;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const ResearchManager = () => {
  const { t } = useTranslation();
  return (
    <OutsideWrapper>
      <Wrapper>
        <div className="research_container">
          <ResearchIntro>
            <img src={Discovery} alt="Research Dashboard"></img>
            <h2>{t("pro.researchDashboard.title")}</h2>
            <p>{t("pro.researchDashboard.description")}</p>
          </ResearchIntro>
        </div>
        <div className="research_image">
          <img
            className="research_image_main_mobile"
            src={ResearchUIMobile}
            alt="Research Dashboard"
          ></img>
          <img className="research_image_main" src={ResearchUI} alt="Research Dashboard" />
          <img
            className="research_image_main_mobile"
            src={AlertsUIMobile}
            alt="Research Dashboard"
          ></img>
        </div>

        <img
          className="research_image_mobile"
          src={ResearchUIMobile}
          alt="Research Dashboard"
        ></img>
      </Wrapper>
      <Ellipse />
      <Ellipse top="30%" left="10%" />
    </OutsideWrapper>
  );
};

export default ResearchManager;
