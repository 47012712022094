import React from "react";
import styled from "styled-components";
import FAQCard from "./FAQCard";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Wrapper = styled.div`
  background: ${(props) => (props.pro ? `#111417` : `#F8F7F3`)};
  margin: 0 auto;
  padding: 3rem 4rem 4.5rem;
  display: flex;
  justify-content: center;

  .container {
    width: 65.5em;
  }

  h2 {
    font-size: 32px;
    text-align: center;
    margin: 0 0 4rem;
  }
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const FAQ = ({ id, isPro = false }) => {
  const { t } = useTranslation();

  const binance =
    "https://www.binance.com/en/support/announcement/a976b8105e0c48179b29c1757f9a623f";

  const hacken =
    "https://medium.com/keyfi-news/second-hacken-smart-contract-audit-complete-dd9a16e30efe";

  const faq = [
    {
      title: t("homepage.faq.whatIsKeyFi.question"),
      answer: (
        <>
          <p>{t("homepage.faq.whatIsKeyFi.ans")}</p>
          <br />
          <p>
            <Trans>
              a <Link to={binance}>Binance.com airdrop</Link>
            </Trans>
            <Trans>
              its <Link to="https://keyfi.com/roadmap/">roadmap</Link>.
            </Trans>
          </p>
        </>
      ),
    },
    {
      title: t("homepage.faq.whatCanYouDo.question"),
      answer: (
        <>
          <p>{t("homepage.faq.whatCanYouDo.ans")}</p>
        </>
      ),
    },
    {
      title: t("homepage.faq.offerToPro.question"),
      answer: (
        <>
          <p>{t("homepage.faq.offerToPro.ans")}</p>
          <p>{t("homepage.faq.offerToPro.ans2")}</p>
          <p>{t("homepage.faq.offerToPro.ans3")}</p>
        </>
      ),
    },
    {
      title: t("homepage.faq.problemSolving.question"),
      answer: (
        <>
          <p>{t("homepage.faq.problemSolving.ans")}</p>
          <p>{t("homepage.faq.problemSolving.ans2")}</p>
          <p>{t("homepage.faq.problemSolving.ans3")}</p>
        </>
      ),
    },
    {
      title: t("homepage.faq.useCase.question"),
      answer: (
        <>
          <div>
            <h4>{t("homepage.faq.useCase.platformIncentives.title")}</h4>
            <p>{t("homepage.faq.useCase.platformIncentives.description")}</p>
          </div>
          <div>
            <h4>{t("homepage.faq.useCase.governance.title")}</h4>
            <Trans>
              democratic <Link to="https://vote.keyfi.com/">voting system</Link>
              .
            </Trans>
          </div>
          <div>
            <h4>{t("homepage.faq.useCase.accessingPremiumFeatures.title")}</h4>
            <p>
              {t("homepage.faq.useCase.accessingPremiumFeatures.description")}
            </p>
          </div>
        </>
      ),
    },
    {
      title: t("homepage.faq.howDoIBuy.question"),
      answer: (
        <>
          <p>
            <Trans>
              Read User Guide
              <Link to="https://docs.keyfi.com/getting-started/buy-keyfi">
                here
              </Link>
            </Trans>
          </p>
        </>
      ),
    },
    {
      title: t("homepage.faq.whichBlockchains.question"),
      answer: (
        <>
          <p>
            <Trans>
              our <Link to="https://keyfi.com/roadmap/">roadmap</Link>
            </Trans>
          </p>
        </>
      ),
    },
    {
      title: t("homepage.faq.different.question"),
      answer: (
        <>
          <p>{t("homepage.faq.different.ans")}</p>
        </>
      ),
    },
    {
      title: t("homepage.faq.safe.question"),
      answer: (
        <>
          <p>
            <Trans>
              by <Link to={hacken}>Hacken</Link>
            </Trans>
          </p>
        </>
      ),
    },
  ];
  return (
    <Wrapper id={id} pro={isPro}>
      <div className="container">
        <h2>{t("homepage.faq.title")}</h2>
        {faq.map((item) => (
          <FAQCard key={item.title} title={item.title} isPro={isPro}>
            {item.answer}
          </FAQCard>
        ))}
      </div>
    </Wrapper>
  );
};

export default FAQ;
