import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

// Assets
import Strategize from "../../images/pro/Strategize.svg";
import Simulate from "../../images/pro/Simulate.svg";
import Monitoring from "../../images/pro/Monitoring.svg";
import Discover from "../../images/pro/Discover.svg";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1300px;
  padding: 8rem 1.25rem;
  margin: 0 auto;
  display: flex;
  gap: 2.75rem;
  .defi_title {
    flex: 1;
    h2 {
      font-size: 42px;
      font-weight: 700;
      margin: 0 0 1rem;
    }
    p {
      color: ${(props) => props.theme.proText};
      line-height: 32px;
    }
    @media (max-width: ${breakPoints.md}px) {
      text-align: center;
      h2 {
        font-size: 38px;
      }
      p {
        font-size: 20px !important;
        line-height: 30px;
      }
    }
  }
  .defi_ellipse {
    position: absolute;
    background: radial-gradient(
      56.93% 56.93% at 32.12% 43.07%,
      #1c0f6b 0%,
      rgba(17, 20, 23, 0) 73.36%
    );
    opacity: 0.4;
    top: 15%;
    left: 50%;
    width: 700px;
    height: 700px;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    flex-direction: column;
    padding: 8rem 1.25rem 5rem;
  }
`;

const DeFiGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  flex: 2;
  gap: 2.75rem;
  z-index: 3;
  .defi_card {
    min-height: 250px;
    background: linear-gradient(
      116.14deg,
      #171e29 73.17%,
      rgba(74, 91, 114, 0) 123.39%
    );
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
    padding: 2rem 1.25rem;
    z-index: 3;
    h3 {
      font-size: 22px;
      margin: 1rem 0;
    }
    p {
      font-family: Montserrat;
      font-size: 18px;
      color: ${(props) => props.theme.proText};
      line-height: 25px;
    }
  }
`;

const DeFiTools = () => {
  const { t } = useTranslation();

  const toolsData = [
    {
      img: <img src={Strategize} alt="KeyFi Pro Strategize"></img>,
      title: t("pro.nextGenerationTool.designStrategies.title"),
      desc: t("pro.nextGenerationTool.designStrategies.description"),
    },
    {
      img: <img src={Simulate} alt="KeyFi Pro Simulate"></img>,
      title: t("pro.nextGenerationTool.simulate.title"),
      desc: t("pro.nextGenerationTool.simulate.description"),
    },
    {
      img: <img src={Monitoring} alt="KeyFi Pro Monitoring"></img>,
      title: t("pro.nextGenerationTool.monitor.title"),
      desc: t("pro.nextGenerationTool.monitor.description"),
    },
    {
      img: <img src={Discover} alt="KeyFi Pro Discover"></img>,
      title: t("pro.nextGenerationTool.discover.title"),
      desc: t("pro.nextGenerationTool.discover.description"),
    },
  ];
  return (
    <Wrapper>
      <div className="defi_title">
        <h2>{t("pro.nextGenerationTool.title")}</h2>
        <p>{t("pro.nextGenerationTool.description")}</p>
      </div>
      <DeFiGrid>
        {toolsData.map((item) => (
          <div key={item.title} className="defi_card">
            {item.img}
            <h3>{item.title}</h3>
            <p>{item.desc}</p>
          </div>
        ))}
      </DeFiGrid>
      <div className="defi_ellipse"></div>
    </Wrapper>
  );
};

export default DeFiTools;
