import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

// Assets and Helpers
import ProUI from "../../images/pro/Dashboard2.png";
import Button from "../Button";
import { breakPoints } from "../theme";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  padding: 0 1.25rem;
  .intro_container {
    padding-top: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .intro_title {
      margin-bottom: 2.5rem;
      z-index: 2;
      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
      }
      h1 {
        margin: 0.25rem 0 0;
        font-size: 80px;
        line-height: 97.5px;
      }
    }
    .intro_desc {
      margin-bottom: 5rem;
      max-width: 1000px;
      font-family: Telex;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      z-index: 2;
      color: ${(props) => props.theme.proText};
    }
    .intro_image {
      margin-bottom: 2.75rem;
      max-width: 1000px;
      width: 100%;
      z-index: 2;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
      @media (max-width: ${breakPoints.md}px) {
        display: none;
      }
    }

    .intro_image_mobile {
      margin-bottom: 2.75rem;
      display: none;
      z-index: 2;
      @media (max-width: ${breakPoints.md}px) {
        display: block;
        max-width: 220px;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
      }
    }
    a {
      z-index: 3;
      margin-top: 2rem;
    }
  }
  .example {
    background: linear-gradient(
      180deg,
      rgba(15, 35, 107, 0) 0%,
      rgba(15, 35, 107, 0.7) 52.71%,
      rgba(15, 35, 107, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1088px;
  }

  @media (max-width: ${breakPoints.lg}px) {
    .intro_container {
      padding-top: 7rem;
      .intro_title {
        h2 {
          font-size: 22px;
        }
        h1 {
          font-size: 50px;
          line-height: 61px;
        }
      }
      .intro_desc {
        font-size: 20px;
        margin-bottom: 1.75rem;
      }
    }
  }
`;

const Intro = () => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <div className="intro_container">
          <div className="intro_title">
            <h2>{t("pro.header")}</h2>
            <h1>{t("pro.title")}</h1>
          </div>
          <p className="intro_desc">{t("pro.description")}</p>
          <img className="intro_image" src={ProUI} alt="KeyFi PRO" />
          <StaticImage
            className="intro_image_mobile"
            src="../../images/pro/Dashboard2Mobile.png"
            alt="KeyFi PRO"
            placeholder="blurred"
            layout="constrained"
          />
          <a href="https://pro.keyfi.com" target="_blank" rel="noreferrer">
            <Button primary>{t("homepage.menu.launchApp")}</Button>
          </a>
        </div>
        <div className="example"></div>
      </Wrapper>
    </>
  );
};

export default Intro;
