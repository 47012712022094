import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: linear-gradient(
    108.52deg,
    #111417 12.55%,
    #17212d 50.76%,
    #111417 88.78%
  );

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 3;
  margin-bottom: 10rem;
  @media (max-width: ${breakPoints.md}px) {
    margin-bottom: 7rem;
  }
  .proinfo_container {
    max-width: 1000px;
    padding: 3.5rem 1.25rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .proinfo_card {
      * {
        margin: 0;
      }
      text-align: center;
      h3 {
        font-size: 48px;
        color: ${(props) => props.theme.proTextHighlight};
        margin-bottom: 0.75rem;
      }
      h5 {
        font-size: 24px;
        font-weight: 500;
      }
    }
    @media (max-width: ${breakPoints.md}px) {
      flex-direction: column;
      gap: 2rem;
      h3 {
        font-size: 38px !important;
      }
      h5 {
        font-size: 22px !important;
      }
    }
  }
`;

const ProInfo = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="proinfo_container">
        <div className="proinfo_card">
          <h3>7,000+</h3>
          <h5>{t("pro.strategyManager.tokenAvailable")}</h5>
        </div>
        <div className="proinfo_card">
          <h3>400+</h3>
          <h5>{t("pro.strategyManager.exchangesTracked")}</h5>
        </div>
        <div className="proinfo_card">
          <h3>50+</h3>
          <h5>{t("pro.strategyManager.newsSources")}</h5>
        </div>
        <div className="proinfo_card">
          <h3>20+</h3>
          <h5>{t("pro.strategyManager.integration")}</h5>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProInfo;
