import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

import News from "../../images/pro/EnhanceNews.png";
import Wallet from "../../images/pro/EnhanceWallet.png";
import Alerts from "../../images/pro/EnhanceAlerts.png";
import Chain from "../../images/pro/EnhanceChain.png";
import { useEffect } from "react";

const Wrapper = styled.div`
  max-width: calc(1200px + 1.25rem);
  position: relative;
  overflow: hidden;
  margin: -3rem auto 10rem;
  padding: 3rem 1.25rem 0;
  text-align: center;

  .enhance_intro {
    position: relative;
    margin-bottom: 4rem;
    z-index: 4;
    h2 {
      font-size: 48px;
      font-weight: 700;
      margin: 0 0 1rem;
    }
    p {
      color: ${(props) => props.theme.proText};
      line-height: 32px;
      width: 80%;
      margin: 0 auto;
    }
    @media (max-width: ${breakPoints.md}px) {
      margin-bottom: 2.5rem;
      h2 {
        font-size: 38px;
      }
      p {
        font-size: 20px !important;
        line-height: 30px;
      }
    }
  }
  .enhance_cards {
    display: flex;
    gap: 2.5rem;
    text-align: left;
    > div {
      position: relative;
      overflow: hidden;
      height: 400px;
      width: 0%;
      min-width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 6px;
      transition: all 0.7s ease-out;
      cursor: pointer;
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-5%);
        height: 100%;
        transition: transform 0.6s ease-out;
      }
      .enhance_card_body {
        display: flex;
        z-index: 2;
        position: relative;
        margin-top: auto;
        height: 70%;
        background: linear-gradient(0deg, #20293e 67.42%, rgba(32, 42, 71, 0) 100%);
        padding: 1.25rem;
        flex-direction: column;
        justify-content: flex-end;
        h3 {
          height: 60%;
          font-size: 22px;
          font-weight: 600;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #c0c1c2;
        }
        p {
          display: none;
          animation: opacity 0.3s linear 0.5s forwards;
        }
      }
    }
    .enhance_card_active {
      width: 100%;
      img {
        left: 0 !important;
        transform: translateX(0) !important;
      }
      .enhance_card_body {
        height: 55%;
        justify-content: flex-end;
        background: linear-gradient(0deg, rgba(19, 22, 24, 0.9) 67.42%, rgba(0, 0, 0, 0) 100%);
        width: 100%;
        h3 {
          display: block;
          height: max-content;
          writing-mode: horizontal-tb;
          transform: rotate(0deg);
          margin: 4rem 0 1rem;
          color: white;
        }
        p {
          min-height: 64.5px;
          opacity: 0;
          font-size: 18px;
          font-weight: 400;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    @media (max-width: ${breakPoints.lg}px) {
      flex-direction: column;
      > div {
      }
    }
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Ellipse = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  left: 24%;
  top: -5%;
  background: radial-gradient(56.93% 56.93% at 40% 43.07%, #131ac2 0%, rgba(17, 20, 23, 0) 73.36%);
  opacity: 0.4;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const cardsData = [
  {
    id: "news",
    title: "News Reader",
    description:
      "Stay up to date with the relevant news that affect your favorite tokens and ultimately, your portfolio. We even organize all news by token for you so you don't have to scour the web.",
    img: News
  },
  {
    id: "wallet",
    title: "Multi-Wallet View",
    description:
      "No more spreadsheets—track multiple wallets on your dashboard, in one unified view. Simply add additional wallet addresses and see all of your assets quickly and conveniently.",
    img: Wallet,
    imgStyle: { transform: "translateX(-45%)" }
  },
  {
    id: "alerts",
    title: "Smart Alerts",
    description:
      "Unlimited price alerts delivered to you by Telegram, email, and soon SMS. Other alert types are currently in development and are arriving soon.",
    img: Alerts,
    imgStyle: { transform: "translateX(-2%)" }
  },
  {
    id: "multichain",
    title: "Multi-Chain Support",
    description:
      "No more painful network switching inside your wallet. Track your assets across multiple, popular blockchains, in pursuit of providing a truly chain-agnostic DeFi experience.",
    img: Chain,
    imgStyle: { left: "0", transform: "translateX(-40%)" }
  }
];

const Enhance = () => {
  const [activeCard, setActiveCard] = useState("news");

  return (
    <Wrapper>
      <Ellipse />
      <div className="enhance_intro">
        <h2>Enhance your DeFi experience</h2>
        <p>
          Introducing the newest asset tracking, market data, and research tools, designed with the
          DeFi power user in mind.
        </p>
      </div>
      <div className="enhance_cards">
        {cardsData.map((item) => (
          <div
            className={
              activeCard === item.id ||
              (typeof window !== "undefined" && window.innerWidth < breakPoints.lg)
                ? "enhance_card_active"
                : " "
            }
            onClick={() => setActiveCard(item.id)}
          >
            <img style={item.imgStyle || {}} src={item.img} alt={item.title} />
            <div className="enhance_card_body">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Enhance;
