import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  color: white;
  padding: 3.25rem 1.25rem;
  background: linear-gradient(
    108.52deg,
    #111417 12.55%,
    #17212d 50.76%,
    #111417 88.78%
  );
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  .mission_container {
    max-width: 1050px;
    margin: 0 auto;
    p {
      margin: 0;
      font-family: Telex;
      font-size: 32px;
      color: ${(props) => props.theme.proText};
      @media (max-width: ${breakPoints.md}px) {
        font-size: 22px;
      }
    }
  }
`;

const OurMissionPro = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="mission_container">
        <p>{t("pro.mission")}</p>
      </div>
    </Wrapper>
  );
};

export default OurMissionPro;
