import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

// Assets
import StrategyUIFull from "../../images/pro/pro_Strategy.png";
import StrategyUIMobile from "../../images/pro/pro_Strategy_Mobile.png";

import StrategyIcon from "../../images/pro/Strategy_White.svg";
import StrategyUI from "../../images/pro/StrategyUI.png";
import { useTranslation } from "react-i18next";

const OutsideWrapper = styled.div`
  position: relative;
`;

const BgEllipse = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 810px;
  background: linear-gradient(
    180deg,
    rgba(15, 35, 107, 0) 0%,
    rgba(15, 35, 107, 0.7) 52.71%,
    rgba(15, 35, 107, 0) 100%
  );
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1.25rem 5rem;
  position: relative;
  z-index: 3;
  .strategy_image {
    margin-top: 4rem;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    width: 100%;
  }
  .strategy_image_mobile {
    display: none;
  }
  @media (max-width: ${breakPoints.md}px) {
    padding-top: 0;
    padding-bottom: 0;
    .strategy_image {
      display: none;
    }
    .strategy_image_mobile {
      display: block;
      margin: 0 auto;
      margin-top: 5rem;
      max-width: 220px;
    }
  }
`;

const StrategyIntro = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h2 {
    font-size: 48px;
    font-weight: 700;
    margin: 1.5rem 0;
  }
  p {
    color: ${(props) => props.theme.proText};
    line-height: 32px;
  }
  @media (max-width: ${breakPoints.md}px) {
    img {
      max-height: 50px;
    }
    h2 {
      font-size: 38px;
      margin: 1.25rem 0 1.5rem;
    }
    p {
      font-size: 20px !important;
      line-height: 30px;
    }
  }
`;

const StrategyMainSection = styled.div`
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  .strategy_main_card {
    overflow: hidden;
    position: relative;
    display: flex;
    padding: 2rem;
    margin-bottom: 3.75rem;
    width: 100%;
    max-height: 310px;
    background: linear-gradient(116.14deg, #101926 -22.83%, rgba(74, 91, 114, 0) 123.39%);

    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
    .strategy_main_card_text {
      flex: 10;
      h4 {
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 1rem;
      }
      p {
        font-family: Montserrat;
        color: ${(props) => props.theme.proText};
        font-size: 18px;
        margin-right: 3rem;
        line-height: 25px;
      }
    }
    img {
      flex: 11;
      max-width: 500px;
      display: block;
      z-index: 2;
      object-fit: contain;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    }
    @media (max-width: ${breakPoints.md}px) {
      flex-direction: column;
      max-height: none;
      padding: 1.5rem 1.25rem;
      margin-bottom: 2.75rem;
      .strategy_main_card_text {
        p {
          margin: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
`;

const Ellipse = styled.div`
  position: absolute;
  top: -40%;
  left: 50%;
  height: 600px;
  width: 600px;
  background: ${(props) =>
    props.color === "purple"
      ? `
radial-gradient(64.09% 48.74% at 56.54% 50%, #5A3FFF 0%, rgba(28, 36, 46, 0.35) 73.36%)`
      : props.color === "gold"
      ? `radial-gradient(64.09% 48.74% at 56.54% 50%, #FFC804 0%, rgba(28, 36, 46, 0.35) 73.36%)`
      : `radial-gradient(64.09% 48.74% at 56.54% 50%,#43f4ff 0%, rgba(28, 36, 46, 0) 73.36%)`};
  opacity: 0.3;
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
`;

const StrategyManager = () => {
  const { t } = useTranslation();

  const strategyData = [
    {
      title: t("pro.strategyManager.design.title"),
      desc: t("pro.strategyManager.design.description"),
      img: <img src={StrategyUI} alt="KeyFi Pro Strategy UI" />,
      ellipse: <Ellipse />
    }
    // {
    //   title: t("pro.strategyManager.simulate.title"),
    //   desc: t("pro.strategyManager.simulate.description"),
    //   img: <img src={StrategyUI2} alt="KeyFi Pro Strategy UI"></img>,
    //   ellipse: <Ellipse color="purple" />
    // },
    // {
    //   title: t("pro.strategyManager.optimize.title"),
    //   desc: t("pro.strategyManager.optimize.description"),
    //   img: <img src={StrategyUI3} alt="KeyFi Pro Strategy UI"></img>,
    //   ellipse: <Ellipse color="gold" />
    // }
  ];
  return (
    <OutsideWrapper>
      <Wrapper>
        <StrategyIntro>
          <img src={StrategyIcon} alt="KeyFi Pro Strategy Manager"></img>
          <h2>{t("pro.strategyManager.title")}</h2>
          <p>{t("pro.strategyManager.description")}</p>
        </StrategyIntro>
        <img className="strategy_image" src={StrategyUIFull} alt="KeyFi Pro Strategy UI" />
        <img className="strategy_image_mobile" src={StrategyUIMobile} alt="KeyFi Pro Strategy UI" />
        <StrategyMainSection>
          {strategyData.map((item) => (
            <div className="strategy_main_card" key={item.title}>
              <div className="strategy_main_card_text">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
              {item.img}
              {item.ellipse}
            </div>
          ))}
        </StrategyMainSection>
      </Wrapper>
      <BgEllipse />
    </OutsideWrapper>
  );
};

export default StrategyManager;
