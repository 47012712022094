import React from "react";
import styled from "styled-components";

// Assets
import Simulate from "../../images/pro/ProSimulate.svg";
import Centralized from "../../images/pro/ProCentralized.svg";
import Optimise from "../../images/pro/Optimise.svg";
import { breakPoints } from "../theme";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  max-width: 1200px;
  position: relative;
  overflow: hidden;
  margin: -2rem auto 4rem;
  padding: 2rem 1.25rem 8rem;
  text-align: center;
  .experience_intro {
    position: relative;
    margin-bottom: 5rem;
    z-index: 4;
    h2 {
      font-size: 48px;
      font-weight: 700;
      margin: 0 0 1rem;
    }
    p {
      color: ${(props) => props.theme.proText};
      line-height: 32px;
    }
    @media (max-width: ${breakPoints.md}px) {
      margin-bottom: 2.5rem;
      h2 {
        font-size: 38px;
      }
      p {
        font-size: 20px !important;
        line-height: 30px;
      }
    }
  }
  .experience_section {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    * {
      z-index: 2;
    }
    .experience_card {
      flex: 1;
      h4 {
        font-size: 22px;
        font-weight: 600;
        margin: 1rem 0;
      }
      p {
        font-size: 18px;
        color: ${(props) => props.theme.proText};
        line-height: 24px;
      }
    }
    @media (max-width: ${breakPoints.md}px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    padding: 0 1.25rem;
  }
`;

const Ellipse = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  left: 33%;
  top: 0%;
  background: radial-gradient(56.93% 56.93% at 40% 43.07%, #131ac2 0%, rgba(17, 20, 23, 0) 73.36%);
  opacity: 0.4;
  @media (max-width: ${breakPoints.lg}px) {
    display: none;
  }
`;

const AdvancedTools = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="experience_intro">
        <h2>{t("pro.enchance.title")}</h2>
        <p>{t("pro.enchance.description")}</p>
      </div>
      <div className="experience_section">
        <div className="experience_card">
          <img src={Simulate} alt="Simulate"></img>
          <h4>{t("pro.enchance.smartAlerts.title")}</h4>
          <p>{t("pro.enchance.smartAlerts.description")}</p>
        </div>
        <div className="experience_card">
          <img src={Optimise} alt="Dark Mode"></img>
          <h4>{t("pro.enchance.darkMode.title")}</h4>
          <p>{t("pro.enchance.darkMode.description")}</p>
        </div>
        <div className="experience_card">
          <img src={Centralized} alt="Centralized"></img>
          <h4>{t("pro.enchance.CeFiIntegrations.title")}</h4>
          <p>{t("pro.enchance.CeFiIntegrations.description")}</p>
        </div>
      </div>
      <Ellipse />
    </Wrapper>
  );
};

export default AdvancedTools;
