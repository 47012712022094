import React from "react";
import styled from "styled-components";
import { breakPoints } from "../theme";

// Assets
import DiscoverUI from "../../images/pro/RecommendationEngine.png";
import DiscoverUIMobile from "../../images/pro/RecommendationEngineMobile.png";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10rem 1.25rem 7rem;
  position: relative;
  overflow: hidden;
  @media (max-width: ${breakPoints.md}px) {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
`;

const DiscoverIntro = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3.5rem;
  * {
    z-index: 2;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    margin: 0 0 1rem;
  }
  p {
    max-width: 85%;
    text-align: center;
    color: ${(props) => props.theme.proText};
    line-height: 32px;
  }
  @media (max-width: ${breakPoints.md}px) {
    text-align: center;
    h2 {
      font-size: 38px;
    }
    p {
      font-size: 20px !important;
      line-height: 30px;
      max-width: 100%;
    }
  }
`;

const DiscoverSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  gap: 4rem;
  * {
    z-index: 2;
  }
  .discover_card {
    margin-bottom: 2.5rem;
    h4 {
      font-size: 22px;
      margin: 0 0 1rem;
    }
    p {
      font-size: 18px;
      font-family: Montserrat;
      color: ${(props) => props.theme.proText};
      line-height: 24px;
    }
  }
  .discover_image {
    max-width: 770px;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  }
  .discover_image_mobile {
    display: none;
  }
  @media (max-width: ${breakPoints.md}px) {
    flex-direction: column;
    padding: 0;
    gap: 0;
    .discover_image {
      display: none;
    }
    .discover_image_mobile {
      display: block;
      max-width: 220px;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    }
  }
`;

const Ellipse = styled.div`
  position: absolute;
  width: 1100px;
  height: 1100px;
  left: 10%;
  top: 20%;

  background: radial-gradient(56.93% 56.93% at 42% 43.07%, #131ac2 0%, rgba(17, 20, 23, 0) 73.36%);
  opacity: 0.4;
  @media (max-width: ${breakPoints.md}px) {
    display: none;
  }
`;

const Discover = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <DiscoverIntro>
        <h2>{t("pro.promote.title")}</h2>
        <p>{t("pro.promote.description")}</p>
      </DiscoverIntro>
      <DiscoverSection>
        <div>
          <div className="discover_card">
            <h4>{t("pro.promote.functionality.marketData.title")}</h4>
            <p>{t("pro.promote.functionality.marketData.description")}</p>
          </div>
          <div className="discover_card">
            <h4>{t("pro.promote.functionality.newsfeed.title")}</h4>
            <p>{t("pro.promote.functionality.newsfeed.description")}</p>
          </div>
          <div className="discover_card">
            <h4>{t("pro.promote.functionality.recommendations.title")}</h4>
            <p>{t("pro.promote.functionality.recommendations.description")}</p>
          </div>
          <div className="discover_card">
            <h4>{t("pro.promote.functionality.watchlist.title")}</h4>
            <p>{t("pro.promote.functionality.watchlist.description")}</p>
          </div>
        </div>

        <img className="discover_image" src={DiscoverUI} alt="Discover UI"></img>
        <img className="discover_image_mobile" src={DiscoverUIMobile} alt="Discover UI"></img>
      </DiscoverSection>
      <Ellipse />
    </Wrapper>
  );
};

export default Discover;
